body {
  background-color: whitesmoke;
}

/* Sizes */

span.ui.medium.text {
  font-size: 1em;
}

span.ui.micro.text {
  font-size: 0.71428571em;
}

span.ui.mini.text {
  font-size: 0.78571429em;
}

span.ui.tiny.text {
  font-size: 0.85714286em;
}

span.ui.small.text {
  font-size: 0.92857143em;
}

span.ui.large.text {
  font-size: 1.14285714em;
}

span.ui.big.text {
  font-size: 1.28571429em;
}

span.ui.huge.text {
  font-size: 1.42857143em;
}

span.ui.massive.text {
  font-size: 1.71428571em;
}

/* Inverted */

.ui.tertiary.inverted.button {
  color: #ffffff;
}

.ui.basic.inverted.table {
  background-color: #1b1c1d;
}

/* Users */

$user-colors: crimson, dodgerblue, forestgreen, orangered, darkorchid, violet,
  chartreuse, gold, grey, black;

@each $color in $user-colors {
  $key: index($user-colors, $color);

  .user-#{$key} {
    color: $color;
  }

  line.user-#{$key} {
    stroke: $color;
  }
}

/* Logo */

span.mozica {
  letter-spacing: 0.5em;
}
